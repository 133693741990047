/* 	Reset Styling
----------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
	display: block;
}

body {
	line-height: 1;
	font-family: 'Univers-LT-Light';
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
	outline: none;
	padding: 0px;
	margin: 0px;
}

html {
	/*min-width: 1296px;*/
	width: 100%;
	height: 100%;
	margin: 0 auto 0 auto;
	padding: 0px;
}

body {
	font-family: "Univers-LT-Roman", Arial, Helvetica, sans-serif;
	font-size: 12px;
	margin: 0px;
	padding: 0px;
	height: 100%;
}

#root {
	height: 100%;
}

.app {
	width: 100%;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Univers-LT-Bold", Arial, Helvetica, sans-serif;
}

input,
button,
p,
a {
	font-family: "Univers-LT-Roman", Arial, Helvetica, sans-serif;
}

input {
	font-size: 16px;
	border-radius: 0;
}

a {
	text-decoration: none !important;
}

img,
video {
	border: none;
	max-width: 100%;
	height: auto;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}


/*  Clear Floated Elements
----------------------------------------------------------------------------------------------------*/
.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

/*	Svg Icon System
----------------------------------------------------------------------------------------------------*/
.icon-approve {
	width: 25px;
	height: 25px;
}

.icon-editpen,
.icon-download,
.icon-packingslip,
.icon-summary,
.icon-picklist,
.icon-docket,
.icon-changewarehouse {
	width: 18px;
	height: 18px;
}

.icon-deletebin {
	width: 14px;
	height: 18px;
}

.icon-tipsbubble {
	width: 33px;
	height: 26px;
}

.icon-preeditpen,
.icon-predeletebin,
.icon-export {
	width: 30px;
	height: 30px;
}

.icon-closepre,
.icon-reverse {
	width: 15px;
	height: 15px;
}

.icon-Home,
.icon-Purchase {
	width: 28px;
	height: 28px;
}

.icon-Inventory {
	width: 38px;
	height: 28px;
}

.icon-Devices {
	width: 18px;
	height: 28px;
}

.icon-Client {
	width: 22px;
	height: 28px;
}

.icon-Sales {
	width: 26px;
	height: 28px;
}

.icon-Transfer {
	width: 26px;
	height: 28px;
}

.icon-Return {
	width: 34px;
	height: 28px;
}

.icon-Gotolink {
	width: 30px;
	height: 30px;
}

.icon-Addtolist {
	width: 14px;
	height: 18px;
}

.icon-Checkmod {
	width: 15px;
	height: 18px;
}

.icon-Coilset {
	width: 16px;
	height: 18px;
}

.icon-Batch {
	width: 16px;
	height: 18px;
}

.icon-SubMenuIndicator {
	width: 10px;
	height: 31px;
}

.icon-NewBarcode {
	width: 30px;
	height: 30px;
}

.icon-NewProductionPlan {
	width: 25px;
	height: 25px;
}

.icon-CompleteProductionPlan {
	width: 20px;
	height: 20px;
}

.icon-ProductionPlanReport {
	width: 20px;
	height: 20px;
}

.icon-MatchingBarcode {
	width: 20px;
	height: 20px;
}

.icon-ApproveReport {
	width: 22px;
	height: 20px;
}

.icon-POToSupplier {
	width: 20px;
	height: 20px;
}

.icon-FinalisePO {
	width: 20px;
	height: 20px;
}

.icon-ViewProduction {
	width: 18px;
	height: 18px;
}

/*	Addtional Fonts
----------------------------------------------------------------------------------------------------*/

@font-face {
	font-family: 'Univers-LT-Light';
	src: url('fonts/univers-lt-45-light.eot');
	src: url('fonts/univers-lt-45-light.eot?#iefix') format('embedded-opentype'),
		url('fonts/univers-lt-45-light.woff2') format('woff2'),
		url('fonts/univers-lt-45-light.woff') format('woff'),
		url('fonts/univers-lt-45-light.ttf') format('truetype'),
		url('fonts/univers-lt-45-light.svg#Univers-LT-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Univers-LT-Roman';
	src: url('fonts/univers-lt-55-roman.eot');
	src: url('fonts/univers-lt-55-roman.eot?#iefix') format('embedded-opentype'),
		url('fonts/univers-lt-55-roman.woff2') format('woff2'),
		url('fonts/univers-lt-55-roman.woff') format('woff'),
		url('fonts/univers-lt-55-roman.ttf') format('truetype'),
		url('fonts/univers-lt-55-roman.svg#Univers-LT-Roman') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Univers-LT-Bold';
	src: url('fonts/univers-lt-65-bold.eot');
	src: url('fonts/univers-lt-65-bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/univers-lt-65-bold.woff2') format('woff2'),
		url('fonts/univers-lt-65-bold.woff') format('woff'),
		url('fonts/univers-lt-65-bold.ttf') format('truetype'),
		url('fonts/univers-lt-65-bold.svg#Univers-LT-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}